/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/21/2020
 * @Example 
 */

import {types} from "./W25F2010_actions";

const initialState = {
    getDataGrid: [],
    getCboStatusNews: [],
    getLink: [],

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2010_LOAD_FORM_SUCCESS: {
            return {...state, getDataGrid: action.data};
        }
        case types.W25F2010_GET_CBO_STATUS_NEWS_SUCCESS: {
            return {...state, getCboStatusNews: action.data};
        }
        case types.W25F2010_GET_LINK_SUCCESS: {
            return {...state, getLink: action.data};
        }
        default:
            return state;
    }
}
