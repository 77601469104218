/**
 * @copyright 2019 @ DigiNet
 * @author HOANGNAM
 * @create 06/22/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest, put } from "redux-saga/effects";
import { types } from "./W25F2000_actions";
import { delay } from "redux-saga";

export default function W25F2000Sagas() {
    return [

        watchGetCboAppStatus(),
        watchGetDataGrid(),
        watchDeleteDataGrid()
    ];
}

//get cbo app status.
export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-app-status', data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2000_GET_CBO_APP_STATUS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo app status error');
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2000_GET_CBO_APP_STATUS, getCboAppStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

// get load grids
export function* loadDataGrid(data) {
    try {
        yield delay(300);
        yield put({
            type: types.W25F2000_LOAD_DATA_GRID_SUCCESS,
            data: []
        });
        const response = yield Api.put('/w25f2000/load-grid', data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2000_LOAD_DATA_GRID_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data grid error');
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2000_LOAD_DATA_GRID, loadDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


// get delete data grid
export function* deleteDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2000/delete', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('delete data grid error');
    }
}

export function* watchDeleteDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2000_DELETE_DATA_GRID, deleteDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
