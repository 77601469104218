/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/25/2020
 * @Example
 */

export const types = {
    W25F6000_GET_CBO_RECRUITMENT_CHANEL: "W25F6000_GET_CBO_RECRUITMENT_CHANEL",
    W25F6000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS: "W25F6000_GET_CBO_RECRUITMENT_CHANEL_SUCCESS",
    W25F6000_SEARCH: "W25F6000_SEARCH",
};

// get load form
export function getCboRecruitmentChanel(cb) {
    return {
        type: types.W25F6000_GET_CBO_RECRUITMENT_CHANEL,
        cb
    }
}

export function search(params, cb) {
    return {
        type: types.W25F6000_SEARCH,
        params,
        cb
    }
}
