/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 5/5/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W21F2005_actions";
import { delay } from "redux-saga";

export default function W29F2005Sagas() {
  return [watchGetGrid(), watchGetCboType(), watchGetCboGrantType()];
}

export function* getGrid(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/w21f2005/load-grid", data.params);
    if (res && res.data) {
      yield put({
        type: types.W21F2005_GET_GRID_SUCCESS,
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchGetGrid() {
  while (true) {
    const watcher = yield takeLatest(types.W21F2005_GET_GRID, getGrid);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboGrantType(data) {
  try {
    yield delay(300);
    const res = yield Api.put("/w21f2005/get-cbo-grant-types", data.params);
    if (res && res.data) {
      yield put({
        type: types.W21F2005_GET_GRANT_TYPE_SUCCESS,
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchGetCboGrantType() {
  while (true) {
    const watcher = yield takeLatest(types.W21F2005_GET_GRANT_TYPE, getCboGrantType);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboType(data) {
  try {
    yield delay(300);
    const res = yield Api.get("/w21f2005/get-cbo-types",data.params);
    if (res && res.data) {
      yield put({
        type: types.W21F2005_GET_TYPES_SUCCESS,
        data: res.data,
      });
      data.cb && data.cb(null, res.data);
      return;
    } else {
      data.cb && data.cb(res, null);
    }
  } catch (e) {}
}

export function* watchGetCboType() {
  while (true) {
    const watcher = yield takeLatest(types.W21F2005_GET_TYPES, getCboType);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
