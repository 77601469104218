import { types } from "./W21F2010_actions";

const initialState = {
    getCboStatus: [],
    getGridConfirm: {},
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.CONFIRM_GET_CBO_STATUS_SUCCESS:
            return {
                ...state,
                getCboStatus: action.data
            };
        case types.CONFIRM_GET_GRID_SUCCESS:
            return {
                ...state,
                getGridConfirm: action.data
            };
        default:
            return state;
    }
}