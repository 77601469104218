import {types} from "./W25F2000_actions";

const initialState = {
    getCboAppStatus: [],
    getDataGrid: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2000_GET_CBO_APP_STATUS_SUCCESS:
            return {
                ...state,
                getCboAppStatus: action.data
            };
        case types.W25F2000_LOAD_DATA_GRID_SUCCESS:
            return {
                ...state,
                getDataGrid: action.data
            };

        default:
            return state;
    }
}