/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 5/5/2020
 * @Example
 */

import { types } from "./W21F2005_actions";

const initialState = {
  getGrid: [],
  getCboGrantType: [],
  getCboTypes: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case types.W21F2005_GET_GRID_SUCCESS: {
      return { ...state, getGrid: action.data };
    }
    case types.W21F2005_GET_GRANT_TYPE_SUCCESS: {
      return { ...state, getCboGrantType: action.data };
    }
    case types.W21F2005_GET_TYPES_SUCCESS: {
      return { ...state, getCboTypes: action.data };
    }
    default: {
      return state;
    }
  }
}
