export const types = {
      W25F2023_LOAD_TAB_VIEW: "W25F2023_LOAD_TAB_VIEW",
      W25F2023_LOAD_TAB_VIEW_SUCCESS: "W25F2023_LOAD_TAB_VIEW_SUCCESS",
      W25F2023_LOAD_INTERVIEW_SCHEDULE: "W25F2023_LOAD_INTERVIEW_SCHEDULE",
      W25F2023_LOAD_INTERVIEW_SCHEDULE_SUCCESS: "W25F2023_LOAD_INTERVIEW_SCHEDULE_SUCCESS",
      W25F2023_CHOOSE_A_CURATOR: "W25F2023_CHOOSE_A_CURATOR",
      W25F2023_CHOOSE_A_CURATOR_SUCCESS: "W25F2023_CHOOSE_A_CURATOR_SUCCESS",
};

export function loadTabView(params, cb) {
      return {
            type: types.W25F2023_LOAD_TAB_VIEW,
            params,
            cb
      };
}

export function loadInterviewSchedule(params, cb) {
      return {
            type: types.W25F2023_LOAD_INTERVIEW_SCHEDULE,
            params,
            cb
      };
}

export function chooseACurator(params, cb) {
      return {
            type: types.W25F2023_CHOOSE_A_CURATOR,
            params,
            cb
      };
}




