/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/25/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W25F2002_actions";
import { delay } from "redux-saga";

export default function W25F2002Sagas() {
    return [
        watchGetLoadForm(),
        watchGetCount(),
        watchAdd(),
        watchEdit(),
        watchGetCboPlan(),
        watchGetCboReason()
    ];
}

export function* getLoadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2002/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) { }
}

export function* watchGetLoadForm() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2002_LOAD_FORM, getLoadForm
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCount(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2002/count", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) { }
}

export function* watchGetCount() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2002_GET_COUNT, getCount
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2002/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) { }
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2002_ADD, add
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2002/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) { }
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2002_EDIT, edit
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboPlan(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f2002/cbo-overall-plan");
        if (response && response.data) {
            yield put({ type: types.W25F2002_GET_CBO_PLAN_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo plan eror");
    }
}

export function* watchGetCboPlan() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2002_GET_CBO_PLAN, getCboPlan);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboReason(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f2002/cbo-reason");
        if (response && response.data) {
            yield put({ type: types.W25F2002_GET_CBO_REASON_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo reason eror");
    }
}

export function* watchGetCboReason() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2002_GET_CBO_REASON, getCboReason);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
