/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/21/2020
 * @Example 
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W25F2010_actions";
import { delay } from "redux-saga";

export default function W25F2010Sagas() {
    return [
        watchGetLoadForm(),
        watchGetLink(),
        watchDeleteData(),
        watchGetCboStatusNew(),
    ];
}

export function* getLoadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2010/load-form", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2010_LOAD_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchGetLoadForm() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2010_LOAD_FORM, getLoadForm
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getLink(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f2010/get-link");
        if (response && response.data) {
            yield put({ type: types.W25F2010_GET_LINK_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchGetLink() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2010_GET_LINK, getLink
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2010/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2010_DELETE_DATA, deleteData
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboStatusNew(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2010/load-cbo-status-believe", data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2010_GET_CBO_STATUS_NEWS_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {}
}

export function* watchGetCboStatusNew() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2010_GET_CBO_STATUS_NEWS, getCboStatusNew
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
