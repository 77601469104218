/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 5/10/2020
 * @Example
 */

export const types = {
    W25F2041_GET_SALARY_CAPTION: "W25F2041_GET_SALARY_CAPTION",
    W25F2041_GET_SALARY_CAPTION_SUCCESS: "W25F2041_GET_SALARY_CAPTION_SUCCESS",
    W25F2041_GET_Coefficient_CAPTION: "W25F2041_GET_Coefficient_CAPTION",
    W25F2041_GET_Coefficient_CAPTION_SUCCESS: "W25F2041_GET_Coefficient_CAPTION_SUCCESS",
    W25F2041_GET_CBO_CANDIDATE: "W25F2041_GET_CBO_CANDIDATE",
    W25F2041_GET_CBO_CANDIDATE_SUCCESS: "W25F2041_GET_CBO_CANDIDATE_SUCCESS",
};

export function loadSalaryCaption(params, cb) {
    return {
        type: types.W25F2041_GET_SALARY_CAPTION,
        params,
        cb,
    };
}

export function loadCoefficientCaption(params, cb) {
    return {
        type: types.W25F2041_GET_Coefficient_CAPTION,
        params,
        cb,
    };
}

export function getCboCandidate(params, cb) {
    return {
        type: types.W25F2041_GET_CBO_CANDIDATE,
        params,
        cb,
    };
}
