/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/25/2020
 * @Example
 */

export const types = {
    W25F2002_LOAD_FORM: "W25F2002_LOAD_FORM",
    W25F2002_LOAD_FORM_SUCCESS: "W25F2002_LOAD_FORM_SUCCESS",
    W25F2002_GET_COUNT: "W25F2002_GET_COUNT",
    W25F2002_GET_COUNT_SUCCESS: "W25F2002_GET_COUNT_SUCCESS",
    W25F2002_ADD: "W25F2002_ADD",
    W25F2002_EDIT: "W25F2002_EDIT",
    W25F2002_GET_CBO_PLAN: "W25F2002_GET_CBO_PLAN",
    W25F2002_GET_CBO_PLAN_SUCCESS: "W25F2002_GET_CBO_PLAN_SUCCESS",
    W25F2002_GET_CBO_REASON: "W25F2002_GET_CBO_REASON",
    W25F2002_GET_CBO_REASON_SUCCESS: "W25F2002_GET_CBO_REASON_SUCCESS",
};

// get load form
export function getLoadForm(params, cb) {
    return {
        type: types.W25F2002_LOAD_FORM,
        params,
        cb
    }
}

// add data
export function add(params, cb) {
    return {
        type: types.W25F2002_ADD,
        params,
        cb
    }
}

export function edit(params, cb) {
    return {
        type: types.W25F2002_EDIT,
        params,
        cb
    }
}

// edit data
export function getCount(params, cb) {
    return {
        type: types.W25F2002_GET_COUNT,
        params,
        cb
    }
}

// get cbo plan
export function getCboPlan(cb) {
    return {
        type: types.W25F2002_GET_CBO_PLAN,
        cb
    }
}

// get cbo reason
export function getCboReason(cb) {
    return {
        type: types.W25F2002_GET_CBO_REASON,
        cb
    }
}