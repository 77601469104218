
import Api from '../../../services/api';
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../W25F2303/W25F2303_actions";
import { delay } from "redux-saga";

export default function W25F2303Sagas() {
    return [
        watchGetCboCandidate(),
        watchSave(),
        watchGetListInterview(),
        watchGetDataForm(),
        watchConfirm(),
        watchGetCboMailTemplate(),
    ];
}


export function* getCboCandidate(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2303/load-combo-candidate', data.params);
        if (response && response.data) {
            yield put({ type: types.W25F2303_LOAD_CBO_CANDIDATE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get Cbo Candidate error', e);
    }

}

export function* watchGetCboCandidate() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2303_LOAD_CBO_CANDIDATE, getCboCandidate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* getListInterview(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w25f2303/load-interviewer');
        if (response && response.data) {
            yield put({ type: types.W25F2303_GET_LIST_INTERVIEW_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get list interview error', e);
    }

}

export function* watchGetListInterview() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2303_GET_LIST_INTERVIEW, getListInterview);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w25f2303/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add data error',e);
    }

}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2303_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


export function* getDataForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2303/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data form error',e);
    }

}

export function* watchGetDataForm() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2303_GET_DATA_FORM, getDataForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* confirm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2303/confirm', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('confirm error',e);
    }
}

export function* watchConfirm() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2303_CONFIRM, confirm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboMailTemplate(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w25f2033/get-mail-template');
        yield put({ type: types.W25F2303_GET_CBO_MAIL_TEMPLATE_SUCCESS, data: response.data });
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo mail template error',e);
    }
}

export function* watchGetCboMailTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2303_GET_CBO_MAIL_TEMPLATE, getCboMailTemplate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* confirmEmail(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2303/mail-confirm', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('confirm email error');
    }

}

export function* watchConfirmEmail() {
    while (true){
        const watcher = yield takeLatest(types.W25F2303_CHECK_FILE, confirmEmail);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}