import { types } from "./W25F2012_actions";

const initialState = {
  loadStage: [],
  loadInfoCandidate: [],
  loadUserEvaluation: [],
  loadOtherEvaluation: [],
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case types.W25F2012_LOAD_STAGE_SUCCESS:
      return {
        ...state,
        loadStage: action.data
      };
    case types.W25F2012_USER_EVALUATION_SUCCESS:
      return {
        ...state,
        loadUserEvaluation: action.data
      };
    case types.W25F2012_OTHER_EVALUATION_SUCCESS:
      return {
        ...state,
        loadOtherEvaluation: action.data
      };
    case types.W25F2012_LOAD_INFO_CANDIDATE_SUCCESS:
      return {
        ...state,
        loadInfoCandidate: action.data
      };
    default:
      return state;
  }
}
