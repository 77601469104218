/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 5/11/2020
 * @Example
 */

import { types } from "./W25F2040_actions";

const initialState = {
    getSalaryCaption: [],
    getCoefficientCaption: [],
    getCboTrialPeriod: [],
    getCboDirectManager: [],
    getCboRecruitmentFile: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2040_GET_SALARY_CAPTION_SUCCESS: {
            return { ...state, getSalaryCaption: action.data };
        }
        case types.W25F2040_GET_COEFFICIENT_CAPTION_SUCCESS: {
            return { ...state, getCoefficientCaption: action.data };
        }
        case types.W25F2040_GET_CBO_TRIAL_PERIOD_SUCCESS: {
            return { ...state, getCboTrialPeriod: action.data };
        }
        case types.W25F2040_GET_CBO_DIRECT_MANAGER_SUCCESS: {
            return { ...state, getCboDirectManager: action.data };
        }
        case types.W25F2040_GET_CBO_RECRUITMENT_FILE: {
            return { ...state, getCboRecruitmentFile: action.data };
        }
        default: {
            return state;
        }
    }
}
