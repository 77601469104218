/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/18/2020
 * @Example 
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest, takeEvery } from "redux-saga/effects";
import { types } from "./W25F2015_actions";
import { delay } from "redux-saga";

export default function W25F2015Sagas() {
    return [
        watchGetLoadInfoEmployment(),
        watchLoadStage(),
        watchGetListCandidate(),
        watchUpdateStage(),
        // watchGetInfoCandidate(),
        // watchGetCache(),
        watchGetLink(),
        // watchGetCount(),
        watchDeteleConfirm(),
        watchDeleteCandidate(),
        watchChooseCandidate(),
        watchGetCboMailTemplate(),
        watchLoadMailData(),
        watchSendMail(),
    ];
}

export function* getLoadInfoEmployment(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2015/load-info-employment", data.params);
        if (response && response.data) {
            yield put({
                type: types.W25F2015_LOAD_INFO_EMPLOYMENT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get cbo status error');
    }
}

export function* watchGetLoadInfoEmployment() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2015_LOAD_INFO_EMPLOYMENT, getLoadInfoEmployment
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadStage(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2012/load-stage", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('load stage error');
    }
}

export function* watchLoadStage() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2015_LOAD_STAGE, loadStage
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getListCandidate(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2015/get-list-candidate", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('load stage error');
    }
}

export function* watchGetListCandidate() {
    while (true) {
        const watcher = yield takeEvery(
            types.W25F2015_GET_LIST_CANDIDATE, getListCandidate
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* updateStage(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2015/update-stage", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('update stage error');
    }
}

export function* watchUpdateStage() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2015_UPDATE_STATE, updateStage
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

// export function* getInfoCandidate(data) {
//     try {
//         yield delay(300);
//         const response = yield Api.get("/w25f2015/get-info-candidate", data.params);
//         if (response && response.data) {
//             data.cb && data.cb(null, response.data);
//             return;
//         } else {
//             data.cb && data.cb(response, null);
//         }
//     } catch (e) {
//         console.log('get cbo status error');
//     }
// }

// export function* watchGetInfoCandidate() {
//     while (true) {
//         const watcher = yield takeLatest(
//             types.W25F2015_GET_INFO_CANDIDATE, getInfoCandidate
//         );
//         yield take(["LOGOUT", "NETWORK"]);
//         yield cancel(watcher);
//     }
// }

// export function* getCache(data) {
//     try {
//         yield delay(300);
//         const response = yield Api.get("/w25f2015/get-cache");
//         if (response && response.data) {
//             data.cb && data.cb(null, response.data);
//             return;
//         } else {
//             data.cb && data.cb(response, null);
//         }
//     } catch (e) {
//         console.log('get cache error');
//     }
// }

// export function* watchGetCache() {
//     while (true) {
//         const watcher = yield takeLatest(
//             types.W25F2015_GET_CACHE, getCache
//         );
//         yield take(["LOGOUT", "NETWORK"]);
//         yield cancel(watcher);
//     }
// }
export function* getLink(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w25f2010/get-link");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get link error');
    }
}

export function* watchGetLink() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2015_GET_LINK, getLink
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
// export function* getCount(data) {
//     try {
//         yield delay(300);
//         const response = yield Api.put("/candidate_comment/count");
//         if (response && response.data) {
//             data.cb && data.cb(null, response.data);
//             return;
//         } else {
//             data.cb && data.cb(response, null);
//         }
//     } catch (e) {
//         console.log('get link error');
//     }
// }

// export function* watchGetCount() {
//     while (true) {
//         const watcher = yield takeLatest(
//             types.W25F2015_GET_COUNT, getCount
//         );
//         yield take(["LOGOUT", "NETWORK"]);
//         yield cancel(watcher);
//     }
// }
//DELETE
export function* deleteConfirm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w25f2017/confirm", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('DELETE confirm error');
    }
}

export function* watchDeteleConfirm() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2017_DELETE_CONFIRM, deleteConfirm
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* deleteCandidate(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w25f2017/delete", data.params);
        if (response && response.data) {

            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('DELETE candidate error');
    }
}

export function* watchDeleteCandidate() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2017_DELETE_CANDIDATE, deleteCandidate
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
//CHOOSE CANDIDATE
export function* chooseCandidate(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w25f2015/choose-candidate", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
            return;
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('DELETE candidate error');
    }
}

export function* watchChooseCandidate() {
    while (true) {
        const watcher = yield takeLatest(
            types.W25F2015_CHOOSE_CANDIDATE, chooseCandidate
        );
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get combo mail template
export function* getCboMailTemplate(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2012/load-combo-mail', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('Get combo mail template error');
    }
}

export function* watchGetCboMailTemplate() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2015_GET_CBO_MAIL_TEMPLATE, getCboMailTemplate);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//load mail
export function* loadMailData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w25f2015/load-mail-data', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('Load mail data error');
    }
}

export function* watchLoadMailData() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2015_LOAD_MAIL_DATA, loadMailData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//send mail
export function* sendMail(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w25f2015/send-mail', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('Send mail error');
    }
}

export function* watchSendMail() {
    while (true) {
        const watcher = yield takeLatest(types.W25F2015_SEND_MAIL, sendMail);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}