export const types = {
      W25F2012_USER_EVALUATION: "W25F2012_USER_EVALUATION",
      W25F2012_USER_EVALUATION_SUCCESS: "W25F2012_USER_EVALUATION_SUCCESS",
      W25F2012_OTHER_EVALUATION: "W25F2012_OTHER_EVALUATION",
      W25F2012_OTHER_EVALUATION_SUCCESS: "W25F2012_OTHER_EVALUATION_SUCCESS",
      W25F2012_LOAD_STAGE: "W25F2012_LOAD_STAGE",
      W25F2012_LOAD_STAGE_SUCCESS: "W25F2012_LOAD_STAGE_SUCCESS",
      W25F2012_LOAD_EVALUATION_ELEMENT: "W25F2012_LOAD_EVALUATION_ELEMENT",
      W25F2012_LOAD_EVALUATION_ELEMENT_SUCCESS:
      "W25F2012_LOAD_EVALUATION_ELEMENT_SUCCESS",
      W25F2012_LOAD_INFO_CANDIDATE: "W25F2012_LOAD_INFO_CANDIDATE",
      W25F2012_LOAD_INFO_CANDIDATE_SUCCESS: "W25F2012_LOAD_INFO_CANDIDATE_SUCCESS",
};

export function loadUserEvaluation(params, cb) {
      return {
            type: types.W25F2012_USER_EVALUATION,
            params,
            cb
      };
}

export function loadOtherComment(params, cb) {
      return {
            type: types.W25F2012_OTHER_EVALUATION,
            params,
            cb
      };
}

export function loadStage(params, cb) {
      return {
            type: types.W25F2012_LOAD_STAGE,
            params,
            cb
      };
}

export function loadEvaluationElement(params, cb) {
      return {
            type: types.W25F2012_LOAD_EVALUATION_ELEMENT,
            params,
            cb
      };
}

export function loadInfoCandidate(params, cb) {
      return {
            type: types.W25F2012_LOAD_INFO_CANDIDATE,
            params,
            cb
      };
}
