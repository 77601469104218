/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/25/2020
 * @Example 
 */

import { types } from "./W25F2002_actions";

const initialState = {
    dataCboPlan: [],
    dataCboReason: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2002_GET_CBO_PLAN_SUCCESS: {
            return { ...state, dataCboPlan: action.data };
        }
        case types.W25F2002_GET_CBO_REASON_SUCCESS: {
            return { ...state, dataCboReason: action.data };
        }
        default:
            return state;
    }
}
