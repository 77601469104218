export const types = {
    W25F2011_GET_CBO_WORKINGTYPE: 'W25F2011_GET_CBO_WORKINGTYPE',
    W25F2011_GET_CBO_WORKINGTYPE_SUCCESS: 'W25F2011_GET_CBO_WORKINGTYPE_SUCCESS',
    W25F2011_GET_FORM: 'W25F2011_GET_FORM',
    W25F2011_GET_FORM_SUCCESS: 'W25F2011_GET_FORM_SUCCESS',
    W25F2011_GET_CBO_CRITERIA: 'W25F2011_GET_CBO_CRITERIA',
    W25F2011_GET_CBO_CRITERIA_SUCCESS: 'W25F2011_GET_CBO_CRITERIA_SUCCESS',
    // W25F2011_GET_TAB_WORKFLOW: 'W25F2011_GET_TAB_WORKFLOW',
    // W25F2011_GET_TAB_WORKFLOW_SUCCESS: 'W25F2011_GET_TAB_WORKFLOW_SUCCESS',
    W25F2011_GET_CBO_SUGGESTION: 'W25F2011_GET_CBO_SUGGESTION',
    W25F2011_GET_CBO_MAILTEMPLATE: 'W25F2011_GET_CBO_MAILTEMPLATE',
    W25F2011_GET_CBO_MAILTEMPLATE_SUCCESS: 'W25F2011_GET_CBO_MAILTEMPLATE_SUCCESS',

    W25F2011_GET_CBO_RECRUITMENTFILE: 'W25F2011_GET_CBO_RECRUITMENTFILE',
    W25F2011_GET_CBO_RECRUITMENTFILE_SUCCESS: 'W25F2011_GET_CBO_RECRUITMENTFILE_SUCCESS',
    W25F2011_LOAD_DEFAULT: 'W25F2011_LOAD_DEFAULT',

    W25F2011_SAVE_DATA_INFO: "W25F2011_SAVE_DATA_INFO",
    W25F2011_SAVE_QUESTIONS: "W25F2011_SAVE_QUESTIONS",
    W25F2011_SAVE_CRITERIA: 'W25F2011_SAVE_CRITERIA',
    W25F2011_SAVE_STATUS: 'W25F2011_SAVE_STATUS',
    W25F2011_SAVE_STAGES: 'W25F2011_SAVE_STAGES',

    W25F2011_UPDATE_CRITERIA: 'W25F2011_UPDATE_CRITERIA',
    W25F2011_UPDATE_STAGES: 'W25F2011_UPDATE_STAGES',
    W25F2011_UPDATE_APPROVALOFFER: 'W25F2011_UPDATE_APPROVALOFFER',
    W25F2011_UPDATE_REORDERSTAGES: 'W25F2011_UPDATE_REORDERSTAGES',

    W25F2011_DELETE_CRITERIA: 'W25F2011_DELETE_CRITERIA',
    W25F2011_DELETE_STAGES: 'W25F2011_DELETE_STAGES',
};

export function getCboWorkingType(cb) {
    return{
        type: types.W25F2011_GET_CBO_WORKINGTYPE,
        cb
    }
}

export function getForm(params, cb) {
    return{
        type: types.W25F2011_GET_FORM,
        params,
        cb
    }
}

export function getCboCriteria(params, cb) {
    return{
        type: types.W25F2011_GET_CBO_CRITERIA,
        params,
        cb
    }
}

// export function getTabWorkflow(params, cb) {
//     return{
//         type: types.W25F2011_GET_TAB_WORKFLOW,
//         params,
//         cb
//     }
// }

export function getCboSuggestions(params, cb) {
    return{
        type: types.W25F2011_GET_CBO_SUGGESTION,
        params,
        cb
    }
}

export function getCboMailTemplate(cb) {
    return{
        type: types.W25F2011_GET_CBO_MAILTEMPLATE,
        cb
    }
}

export function getCboRecruitmentFile(params, cb) {
    return {
        type: types.W25F2011_GET_CBO_RECRUITMENTFILE,
        params,
        cb
    }
}

export function loadDefault(params, cb) {
    return {
        type: types.W25F2011_LOAD_DEFAULT,
        params,
        cb
    }
}

//save...
export function saveDataInfo(params, cb) {
    return{
        type: types.W25F2011_SAVE_DATA_INFO,
        params,
        cb
    }
}
export function saveCriteria(params, cb) {
    return{
        type: types.W25F2011_SAVE_CRITERIA,
        params,
        cb
    }
}
export function saveQuestions(params, cb) {
    return{
        type: types.W25F2011_SAVE_QUESTIONS,
        params,
        cb
    }
}
export function saveStatus(params, cb) {
    return{
        type: types.W25F2011_SAVE_STATUS,
        params,
        cb
    }
}
export function saveStages(params, cb) {
    return{
        type: types.W25F2011_SAVE_STAGES,
        params,
        cb
    }
}

//update..
export function updateCriteria(params, cb) {
    return{
        type: types.W25F2011_UPDATE_CRITERIA,
        params,
        cb
    }
}
export function updateStages(params, cb) {
    return{
        type: types.W25F2011_UPDATE_STAGES,
        params,
        cb
    }
}
export function updateApprovalOffer(params, cb) {
    return{
        type: types.W25F2011_UPDATE_APPROVALOFFER,
        params,
        cb
    }
}
export function updateReorderStages(params, cb) {
    return{
        type: types.W25F2011_UPDATE_REORDERSTAGES,
        params,
        cb
    }
}

//delete..
export function deleteCriteria(params, cb) {
    return{
        type: types.W25F2011_DELETE_CRITERIA,
        params,
        cb
    }
}
export function deleteStages(params, cb) {
    return{
        type: types.W25F2011_DELETE_STAGES,
        params,
        cb
    }
}



