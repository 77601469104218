import {types} from "./W25F2011_actions";

const initialState = {
    getCboWorkingType: [],
    getForm: null,
    getCboCriteria: [],
    // getTabWorkflow: [],
    getCboMailTemplate: [],
    getCboRecruitmentFile: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W25F2011_GET_CBO_WORKINGTYPE_SUCCESS:
            return {
                ...state,
                getCboWorkingType: action.data
            };
        case types.W25F2011_GET_FORM_SUCCESS:
            return {
                ...state,
                getForm: action.data
            };
        case types.W25F2011_GET_CBO_CRITERIA_SUCCESS:
            return {
                ...state,
                getCboCriteria: action.data
            };
        // case types.W25F2011_GET_TAB_WORKFLOW_SUCCESS:
        //     return {
        //         ...state,
        //         getTabWorkflow: action.data
        //     };
        case types.W25F2011_GET_CBO_MAILTEMPLATE_SUCCESS:
            return {
                ...state,
                getCboMailTemplate: action.data
            };
        case types.W25F2011_GET_CBO_RECRUITMENTFILE_SUCCESS:
            return {
                ...state,
                getCboRecruitmentFile: action.data,
            }
        default:
            return state;
    }
}